import { hydrateRoot } from 'react-dom/client';

import type { ManifestMap } from '@xing-com/crate-core-assets';
import { createEntryPoint } from '@xing-com/crate-runtime';
import type { ErrorReporter } from '@xing-com/crate-xinglet';
import type {
  GlobalScope,
  Runtime,
  RuntimeConfig,
} from '@xing-com/crate-xinglet/internal';

import { createBrowserRuntime } from './create-browser-runtime';

declare const globalThis: GlobalScope & {
  crateRuntime: Runtime;
  document: typeof document;
};

export async function render({
  config,
  manifestMap,
  reportError,
  startXinglets = [],
  serverData = {},
}: {
  config: RuntimeConfig;
  manifestMap: ManifestMap;
  reportError: ErrorReporter;
  startXinglets: Array<string>;
  serverData: Record<string, unknown>;
}): Promise<void> {
  const { basePath, enableMocks = false, entryPoint, manifestId } = config;

  const runtime = createBrowserRuntime({
    config,
    manifestMap,
    reportError,
    serverData,
  });

  globalThis.crateRuntime = runtime;

  if (enableMocks) {
    // eslint-disable-next-line node/no-unsupported-features/es-syntax
    const { setupMocking } = await import('./mocks');
    await setupMocking(config.mockSessionId);
  }

  // [SSR] if we have startXinglets to load, we do trigger this
  // this is required, since react needs to have all code available to
  // hydrate the server DOM
  await Promise.all(
    [entryPoint, ...startXinglets].map(async (name) => {
      await runtime.loadXingletComponent(name);
    })
  );

  const mountpoint = globalThis.document.querySelector('#app[data-mountpoint]');
  if (!mountpoint) {
    throw new Error('No mountpoint found');
  }

  hydrateRoot(mountpoint, createEntryPoint(runtime, entryPoint, { basePath }), {
    onRecoverableError(error, errorInfo) {
      if (!(error instanceof Error)) {
        reportError(String(error));

        return;
      }

      if (error.message.startsWith(knownSuspenseError)) {
        return;
      }

      reportError(error, errorInfo);
    },
  });

  mountpoint.setAttribute('data-mounted', '');

  if (LOCAL_DEVELOPMENT || manifestId === 'dev') {
    const { startDevMode } = await import('./dev-mode');

    startDevMode(runtime);
  }
}

const knownSuspenseError = 'The server did not finish this Suspense boundary';
