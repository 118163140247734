import type { SessionConfig } from '@xing-com/crate-xinglet/internal';

import type { FetchFunction } from '../types';

const AUTH_STATE_HEADER = 'XING-ONE-AUTHENTICATION-STATE';

const kebabify = (value: string | null): string => {
  return String(value || '')
    .toLowerCase()
    .replace(/_/g, '-');
};

function reload(): void {
  global.document.location.reload();
}

export function configureFetchWithAuthCheck(
  fetch: FetchFunction,
  sessionConfig: SessionConfig
): FetchFunction {
  return (...args) => {
    return fetch(...args).then((response) => {
      const { loginState } = sessionConfig;
      const hopsAuthState = kebabify(loginState);
      const xingOneAuthState = kebabify(
        response.headers.get(AUTH_STATE_HEADER)
      );

      if (
        hopsAuthState &&
        xingOneAuthState &&
        hopsAuthState !== xingOneAuthState
      ) {
        reload();
      }

      return response;
    });
  };
}
